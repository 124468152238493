import { fetchAPI } from "../lib/fetchAPI.js";

export const prerender = true;

export async function load() {
  let res = await fetchAPI("/utils/states", "GET", null);
  let states = await res.json();

  let latestRes = await fetchAPI('/properties/latest', 'GET', null);
	latestRes = await latestRes.json();

  let articleRes = await fetchAPI('/communitychronicle/get_tree', 'GET', null);
  articleRes = await articleRes.json();

  return {
    states: [...states],
    latest: [...latestRes],
    articles: [...articleRes]
  }
}
